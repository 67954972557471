export function setStorage(key, data) {
    if (data !== null && typeof data === 'object') {
        return localStorage.setItem(key, JSON.stringify(data))
    }
    localStorage.setItem(key, data)
}

export function getStorage(key, defaultValue) {
    const result = localStorage.getItem(key)
    if ((result?.startsWith('{') && result?.endsWith('}')) || (result?.startsWith('[') && result?.endsWith(']'))) {
        return JSON.parse(result)
    }
    if (result === 'true') return true
    if (result === 'false') return false
    return result === 'null' || result === null ? defaultValue : result
}

export function removeStorage(key) {
    localStorage.removeItem(key)
}
