import request from '@/utils/request'

/**
 * 根据业务模块保存表格配置
 * @param {Object} data
 * @returns
 */
export function saveGlobalTableConfig(data) {
  return request({
    url: '/mg/drp-enterprise-api/globalTableConfig',
    method: 'put',
    data,
  })
}

/**
 * 根据业务模块获取表格配置
 * @param {Object} data
 * @returns
 */
export function getGlobalTableConfig(businessType) {
  return request({
    url: `/mg/drp-enterprise-api/globalTableConfig/get/${businessType}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 获取门户网站图片
 * @param {*} data
 * @returns
 */
export function getPortalImages(data) {
  return request({
    url: '/mg/drp-enterprise-api/portal/page',
    method: 'post',
    data,
  })
}

/**
 * 获取gzip配置
 * @returns
 */
export function getGzipConfig() {
  return request({
    url: `/mg/system/systemConfig`,
    method: 'get',
  })
}

export function getAliyunToken(data) {
  return request({
    url: '/mg/ecs-api/componentFile/requestSTSToken',
    method: 'post',
    data,
  })
}

export function getDownLoadFil(name, ossName) {
  return request({
    url: `/mg/ecs-api/componentFile/downloadFile/${name}/${ossName}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function workflowByProcessCode(data) {
  return request({
    url: `/mg/workflow-api/task/flowXmlAndNode`,
    method: 'post',
    data,
  })
}
