<template>
  <el-card shadow="never" class="mg-searc-form">
    <el-form ref="form" :model="form" v-bind="$attrs" v-on="$listeners" @keydown.enter.native.prevent="handleSearch">
      <el-row>
        <template v-for="item in renderItemList">
          <el-col v-if="!item.hidden" :key="item.model" :span="item.span || 6">
            <el-form-item v-if="item.turnLabel" v-bind="{ ...item, ref: null }">
              <template v-slot:label>
                <el-dropdown @command="(event) => handleCommand(event, item)">
                  <span
                    class="dropdown-label"
                    style="
                      font-size: 12px;
                      cursor: pointer;
                      font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial,
                        Hiragino Sans GB, Microsoft Yahei, STHeiti, SimSun, sans-serifsans-serif;
                    "
                  >
                    {{ item.label }}
                    <i class="el-icon-caret-bottom el-icon--right dropdown-icon" />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(dropdown, dropIndex) in item.attrs.options"
                      :key="dropIndex"
                      :command="dropdown.model"
                    >
                      {{ dropdown.label }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <el-input
                data-class="mousetrap"
                v-model="form[item.model]"
                :placeholder="item.placeholder"
                clearable
                @clear="() => selectClear(item)"
              />
            </el-form-item>
            <el-form-item
              v-else
              :prop="item.model"
              :class="[item.attrs && item.attrs.type === 'daterange' ? 'mg_date_picker' : '']"
              v-bind="{ ...item, ref: null }"
            >
              <!-- 年区间 -->
              <mg-year-range v-if="item.type === 'yearRange'" v-model="form[item.model]" />
              <!-- 单选 -->
              <el-radio-group
                v-if="item.type === 'radio'"
                v-model="form[item.model]"
                @change="item.change"
                :placeholder="item.placeholder || $MGUI.i18n.t('请输入')"
              >
                <el-radio v-for="radioItem in item.options" :label="radioItem.value" :key="radioItem.value">
                  {{ radioItem.label }}
                </el-radio>
              </el-radio-group>
              <!-- 多选 -->
              <el-checkbox-group
                v-if="item.type === 'checkbox'"
                v-model="form[item.model]"
                @change="item.change"
                :indeterminate="false"
              >
                <el-checkbox v-for="radioItem in item.options" :label="radioItem.value" :key="radioItem.value">
                  {{ radioItem.label }}
                </el-checkbox>
              </el-checkbox-group>
              <!--  搜索input  -->
              <mg-autocomplete
                v-if="item.type === 'autocomplete'"
                popper-class="my-autocomplete"
                v-model="form[item.model]"
                :fetch-suggestions="(queryString, cb) => querySearch(queryString, cb, item)"
                :placeholder="$MGUI.i18n.t('请输入')"
                @select="(value) => handleSelect(value, item)"
                :highlight-first-item="true"
              >
                <template #header>
                  <slot name="header">
                    <template v-for="column in item.columns">
                      <span :style="{ width: column.width }" :key="column.label">{{ column.label }}</span>
                    </template>
                  </slot>
                </template>
                <template slot-scope="{ item: items }">
                  <slot :item="items">
                    <template v-for="column in item.columns">
                      <span :style="{ width: column.width }" :key="column.label">
                        <mg-tooltip tag-type="text" :content="items[column.prop]?.toString()" placement="top">
                          <span>
                            {{ items[column.prop] }}
                          </span>
                        </mg-tooltip>
                      </span>
                    </template>
                  </slot>
                </template>
              </mg-autocomplete>
              <!-- input 文本类型-->
              <el-input
                v-if="item.type === 'input'"
                data-class="mousetrap"
                v-model="form[item.model]"
                :placeholder="item.placeholder || $MGUI.i18n.t('请输入')"
                clearable
                @clear="() => selectClear(item)"
              />
              <!-- number 类型 -->
              <mg-input
                v-if="item.type === 'number'"
                data-class="mousetrap"
                v-model="form[item.model]"
                type="float"
                v-bind="item"
                clearable
                @clear="() => selectClear(item)"
              />
              <!-- 搜索类型 -->
              <el-input
                v-if="item.type === 'inputSearch'"
                data-class="mousetrap"
                v-model="form[item.model]"
                :placeholder="item.placeholder || $MGUI.i18n.t('请输入')"
                clearable
                disabled
                @clear="() => selectClear(item)"
              >
                <el-button
                  slot="append"
                  :icon="form[item.model] ? 'el-icon-sort' : 'el-icon-plus'"
                  @click="() => item.select(item)"
                ></el-button>
              </el-input>
              <!-- select 是否多选根据initialValue属性是否为数组判断；需要传进来options属性为select选择属性 -->
              <mg-select
                v-if="item.type === 'select'"
                data-class="mousetrap"
                v-model="form[item.model]"
                :map="item.map || { label: 'label', code: 'id', value: 'value' }"
                collapse-tags
                clearable
                v-bind="item"
                v-on="{ ...item, hidden: () => {} }"
              />
              <!-- select 树形option选项 -->
              <mg-select-tree
                v-if="item.type === 'selectTree'"
                data-class="mousetrap"
                v-model="form[item.model]"
                v-bind="item"
                v-on="item"
              ></mg-select-tree>
              <el-date-picker
                v-if="item.type === 'date'"
                data-class="mousetrap"
                v-model="form[item.model]"
                style="width: 100%"
                :type="item.attrs.type || 'date'"
                :format="item.attrs.format || 'yyyy-MM-dd'"
                :value-format="item.attrs.type !== 'week' ? item.attrs.valueFormat || 'timestamp' : ''"
                :placeholder="item.placeholder || $MGUI.i18n.t('请选择')"
                :range-separator="item.attrs.rangeSeparator || '至'"
                :picker-options="item.attrs.pickerOptions"
                :start-placeholder="$MGUI.i18n.t('开始日期')"
                :end-placeholder="$MGUI.i18n.t('结束日期')"
                :clearable="item?.initialValue?.length === 0 || !item?.initialValue"
                @change="(event) => dateChange(event, item)"
                :popper-class="item.attrs.popperClass || ''"
              ></el-date-picker>
              <div v-if="item.type === 'date' && item.attrs.type === 'daterange'" class="customize_date">
                <a v-for="time in dateList" size="small" :key="time.value" @click="() => createDate(time, item)">
                  {{ time.name }}
                </a>
              </div>
              <div v-if="item.type === 'range'">
                <el-input-number
                  v-model="form[item.model]"
                  data-class="mousetrap"
                  class="input_number"
                  :placeholder="item.placeholder"
                  :controls="false"
                  @blur="() => rangeBlur('min', item)"
                />
                <span style="padding: 0 5px; font-size: 12px">{{ item.separator }}</span>
                <el-input-number
                  v-model="form[item.maxModel]"
                  data-class="mousetrap"
                  class="input_number"
                  :placeholder="item.maxPlaceholder"
                  :controls="false"
                  @blur="() => rangeBlur('max', item)"
                />
              </div>
              <div v-if="item.type === 'monthrange'" class="monthrange">
                <el-date-picker
                  v-model="form[item.model]"
                  data-class="mousetrap"
                  :type="item.pickerType || 'month'"
                  class="month_range"
                  :format="item.format || 'yyyyMM'"
                  :value-format="item.valueFormat || 'timestamp'"
                  :placeholder="item.placeholder || $MGUI.i18n.t('请选择')"
                  :picker-options="item.minPickerOptions"
                  @change="(event) => dateChange(event, item, 'min')"
                ></el-date-picker>
                <span style="padding: 0 5px; font-size: 12px">{{ item.separator || '-' }}</span>
                <el-date-picker
                  v-model="form[item.maxModel]"
                  data-class="mousetrap"
                  :type="item.pickerType || 'month'"
                  class="month_range"
                  :format="item.format || 'yyyyMM'"
                  :value-format="item.valueFormat || 'timestamp'"
                  :placeholder="item.maxPlaceholder || $MGUI.i18n.t('请选择')"
                  :picker-options="item.maxPickerOptions"
                  @change="(event) => dateChange(event, item, 'max')"
                ></el-date-picker>
              </div>
            </el-form-item>
          </el-col>
        </template>

        <el-col class="button_box" :span="6">
          <el-form-item label-width="0" class="button_position">
            <el-button size="small" type="primary" icon="el-icon-search" @click="handleSearch">
              {{ $MGUI.i18n.t('查询') }}
            </el-button>
            <el-button size="small" icon="el-icon-refresh" @click="handleReset">{{ $MGUI.i18n.t('重置') }}</el-button>
            <template v-if="businessType">
              <el-divider direction="vertical" />
              <el-button type="text" size="small" icon="iconfont mg-icon_set" class="advanced-btn" @click="handleSet">
                {{ $MGUI.i18n.t('设置') }}
              </el-button>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!--  formItem 配置  -->
    <set-form-item
      ref="setFormItem"
      :form-items="renderItemList"
      :business-type="businessType"
      @column-change="handleColumnChange"
    />
  </el-card>
</template>

<script>
  import { SearchForm } from '@mg/mg-ui'
  export default {
    name: SearchForm.name,
    extends: SearchForm,
    methods: {
      handleSearch() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.form.btnType = null
            this.spreadData(this.form)
            if (this.isShowFooterTag) this.expandType = false
          }
        })
      },
    },
  }
</script>
