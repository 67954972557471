<template>
  <div id="app">
    <router-view />
    <div class="first-loading-wrp" v-if="isShowLoading">
        <div class="loading-wrp">
          <span class="dot dot-spin">
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </span>
        </div>
    </div>
  </div>
</template>
<script>
import { actionTimer, clearActionTimer } from './utils'
export default {
  data() {
    return {
      isShowLoading: false
    }
  },
  mounted() {
    this.handleQueryDom()
  },
  methods: {
    handleQueryDom() {
      this.$nextTick(() => {
        const loginDom = document.querySelector('.login-container')
        const layoutContainer = document.querySelector('.layout-container')
        actionTimer('queryDomTimer', this.handleQueryDom, 1000)
        if (!loginDom && !layoutContainer) this.isShowLoading = true
        if (loginDom || layoutContainer) this.isShowLoading = false
        if (layoutContainer) clearActionTimer('queryDomTimer')
      })
    }
  }
}
</script>

<style lang="less">
  #app {
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    .vxe-table--render-default.size--mini .vxe-footer--column.col--ellipsis {
      height: 28px;
    }
    .vxe-body--column.excess-amount input {
      color: red;
      font-weight: 600;
    }
    .rich-text-body {
      width: 608px;
      img {
        width: 30%;
        &:hover {
          cursor: pointer;
        }
      }
      a {
        color: #409eff;
      }
      ul li,
      ol li {
        list-style-type: unset;
      }
    }
  }
  .el-message-box.rich-text-editor {
    width: 1020px;
  }
  .mg-icon_delete,
  .mg-icon_add {
    font-size: 20px;
    cursor: pointer;
  }
  .mg-icon_delete[disabled='disabled'],
  .mg-icon_add[disabled='disabled'] {
    cursor: no-drop;
    filter: grayscale(1);
    pointer-events: none;
  }
  .ecs-form-item-mode {
    .el-form-item--small.el-form-item {
      display: flex;
      margin-bottom: 2px;
    }
    .el-form-item--small .el-form-item__content {
      flex: 1;
      margin-left: 67px;
    }
    .ecs-search-input {
      .el-form-item__error {
        right: 20px;
      }
    }
    // 表单错误信息内部靠右显示
    .el-form-item__error {
      right: 5px;
      left: unset;
      top: 30%;
      z-index: 1;
    }
    .el-input--suffix + .el-form-item__error,
    .ecs-search-input + .el-form-item__error {
      right: 40px;
    }
    .ecs-form-item-exchangeRate {
      .el-form-item__label {
        width: auto !important;
      }
      .el-form-item__content {
        margin-left: 0;
      }
    }
    .ecs-form-isApprove {
      .el-form-item__label {
        display: flex;
        white-space: nowrap;
      }
      .ecs-form-isApprove-icon {
        margin-left: 5px;
      }
    }
  }
  .import-blue-button {
    &.is-disabled {
      color: #fff !important;
      background-color: rgba(29, 84, 241, 0.5) !important;
    }
    span {
      position: relative;
      padding-left: 15px;
      .import-icon {
        width: 13px;
        position: absolute;
        left: -6px;
        top: -1px;
      }
    }
  }

  .upload-icon {
    width: 12px;
    margin-right: 5px;
    vertical-align: -1px;
  }

  .ecs-bill-panel {
    .ecs-bill-titlebar,
    .ecs-form-box {
      width: 1000px;
    }
  }
  .el-form--inline {
    .vxe-table--render-default {
      .el-form-item {
        margin-right: 0px;
      }
    }
  }
  .idcard-info-dialog {
    .id-placeholder-img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      border: 1px dashed #ddd;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.015);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666;
    }
  }
</style>
