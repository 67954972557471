<template>
  <el-container class="layout-container">
    <Aside class="layout-aside" />
    <el-container class="layout-content">
      <el-header class="layout-header">
        <Header />
      </el-header>
      <TabsBar />
      <el-container class="layout-mainContent">
        <Main />
        <div
          :class="['notice-content', showNoticeList ? 'notice-content-show' : 'notice-content-hidden']"
          v-if="isHome"
        >
          <Right v-if="showNoticeList" />
          <img class="notice-icon" src="@/assets/icon/notice-icon.png" @click="showNoticeList = !showNoticeList" />
        </div>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
  import Main from './components/Main'
  import Header from './components/Header'
  import Aside from './components/Aside'
  import Right from './components/Right'
  import TabsBar from './components/TabsBar'

  export default {
    name: 'Layout',
    components: { Main, Header, Aside, Right, TabsBar },
    computed: {
      isHome() {
        return this.$route.path === '/index'
      }
    },
    data() {
      return {
        showNoticeList: true,
      }
    }
  }
</script>

<style lang="scss" scoped>
  .layout-container {
    overflow: hidden;
    .layout-header {
      height: $mg-layout-header-height !important;
      box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
      background: #fff;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 12;
      background: url(../assets/layout/header-bg.png) no-repeat;
      background-size: 100% 100%;
      padding: 0 16px 0 31px;
    }
    .layout-aside,
    .layout-content {
      height: calc(100vh - 8px);
    }
    .layout-mainContent {
      overflow: auto;
      padding: 10px 5px 0 10px;
      .notice-content {
        position: relative;
        width: 0px;
        transition: width 150ms !important;
        .notice-icon {
          display: block;
          width: 20px;
          cursor: pointer;
        }
      }
      .notice-content-hidden {
        .notice-icon {
          position: absolute;
          left: -18px;
          top: 280px;
          transform: rotate(180deg);
        }
      }
      .notice-content-show {
        width: 200px;
        // overflow-y: auto;
        // overflow-x: hidden;
        margin-left: 10px;
        .notice-icon {
          display: none;
          right: 200px;
          top: 373px;
          transform: rotate(0deg);
        }
        .notice-icon {
          position: fixed;
          display: block;
        }
      }
    }
  }
</style>
