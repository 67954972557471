import { getAliyunToken, getGlobalTableConfig, saveGlobalTableConfig } from '@/api/config'
import ElementUI, { Message, MessageBox, Notification } from 'element-ui'
import { Column, Edit, Footer, Keyboard, Table, Tooltip, VXETable } from 'vxe-table'
import XEUtils from 'xe-utils'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import MGUI from '@mg/mg-ui'
import MessageBoxContent from '@/components/message-box-content'
import EventBus from './event-bus'
import { getStorage, setStorage } from '@/utils/storage'
import { messageDuration } from '@/config'
import bigjs from 'big.js'
import MgFixedBarPlus from '@/components-plus/mg-fixed-bar'
import MgContainerPlus from '@/components-plus/mg-container'
import MgVxeTablePlus from '@/components-plus/mg-vxe-table'
import MgSearchFormPlus from '@/components-plus/mg-search-form'
import MgUploadButtonPlus from '@/components-plus/mg-upload-button'
import ECSMessageBox from '@/components/ecs-message-box'
import nzhcn from 'nzh/cn'

const install = (Vue) => {
  /** ElementUI **/
  Vue.use(ElementUI, { size: 'small' })
  /** VXETable */
  VXETable.config({
    zIndex: 2010,
    i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
    table: {
      scrollY: {
        enabled: true,
      },
    },
  })
  Vue.use(Column).use(Footer).use(Keyboard).use(Edit).use(Tooltip).use(Table)
  /** MgUI **/
  Vue.use(MGUI, {
    table: {
      getColumnApi: getGlobalTableConfig,
      setColumnApi: saveGlobalTableConfig,
    },
    utils: {
      getAliyunToken,
    },
    dropdownButton: {
      getStorage,
      setStorage(key, data) {
        setStorage(key, data.printTemplateId)
      },
    },
    // 通用本地缓存配置
    storage: {
      getStorage,
      setStorage,
    },
    BN: bigjs,
  })

  // 使用 MgFixedBarPlus 扩展 mg-fixed-bar 组件
  Vue.component(MgFixedBarPlus.name, MgFixedBarPlus)
  Vue.component(MgContainerPlus.name, MgContainerPlus)
  Vue.component(MgVxeTablePlus.name, MgVxeTablePlus)
  Vue.component(MgSearchFormPlus.name, MgSearchFormPlus)
  Vue.component(MgUploadButtonPlus.name, MgUploadButtonPlus)
  /* 全局消息弹框 */
  Vue.prototype.$mgMessage = function (message, type) {
    Message({
      offset: 60,
      showClose: true,
      message: message,
      type: type,
      dangerouslyUseHTMLString: true,
      duration: messageDuration,
    })
  }

  /* 全局 mgConfirm */
  Vue.prototype.$mgConfirm = function (content, title, options) {
    const h = this.$createElement ? this.$createElement : new Vue({}).$createElement
    return MessageBox.confirm(content, '-', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      showCancelButton: true,
      type: 'warning',
      customClass: 'el-message-box__no-title',
      closeOnClickModal: false,
      message: h(MessageBoxContent, {
        props: {
          title,
          content,
        },
      }),
      ...options,
    })
  }

  /* 全局 mgAlert */
  Vue.prototype.$mgAlert = function (content, title, options) {
    return MessageBox.confirm(content, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      closeOnClickModal: false,
      ...options,
    })
  }

  /* 全局 关联合同提示框 */
  Vue.prototype.$mgRelatedContractAlert = function (content, title, options) {
    return new Promise((resolve, reject) => {
      MessageBox.confirm(content, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        ...options,
      })
        .then(reject)
        .catch(resolve)
    })
  }

  /* 全局 ecsAlert  */
  Vue.prototype.$ecsAlert = function (content, title, options) {
    return ECSMessageBox.confirm({
      content,
      title,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      ...options,
    })
  }

  /* 全局Notification */
  Vue.prototype.$mgNotify = (message, title, type, position) => {
    return Notification({
      title: title,
      message: message,
      position: position || 'top-right',
      type: type || 'success',
      duration: messageDuration,
    })
  }
  /**
   * 编辑的页面确认关闭弹框
   * @param {String} routeName 路由名称
   */
  Vue.prototype.$mgCloseEditPageConfirm = function (routeName) {
    const changePages = this.$store.getters['tabsBar/changePages']
    if (changePages.includes(routeName)) {
      return this.$mgConfirm('当前页面正在编辑，确认关闭?')
    }
  }

  /**
   * 全局格式化
   */
  Vue.prototype.$format = {
    /**
     * 千分法金额格式化
     * @param {number} value
     * @returns
     */
    amount: (value) => {
      if (!value) return value
      return value.toString().replace(/\d+/, (n) => {
        return n.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
      })
    },
    /**
     * 数字转中文
     * @returns
     */
    digitUppercase(value) {
      return nzhcn.toMoney(value, { outSymbol: false })
    },
    /**
     * 【日期】格式化
     * @param {*} date 有效日期数值
     * @param {*} format 默认 YYYY-MM-DD
     * @returns
     */
    date(date, format = 'YYYY-MM-DD') {
      if (!date) return '--'
      return dayjs(date).format(format)
    },
    /**
     * 【日期】获取时长
     */
    dateDiff(date1, date2, isNeedInt = false) {
      if (!date1 && !date2) return '-'

      const startDate = dayjs(date1).valueOf()
      const endDate = dayjs(date2)

      // 计算以天为单位的间隔时长
      const diffDay = endDate.diff(startDate, 'day', isNeedInt)
      // 计算以小时为单位的间隔时长
      const diffHour = endDate.diff(startDate, 'hour', isNeedInt)
      // 计算以分钟为单位的间隔时长
      const diffMinute = endDate.diff(startDate, 'minute', isNeedInt)
      // 计算以秒为单位的间隔时长
      const diffSecond = endDate.diff(startDate, 'second', isNeedInt)

      // 以天为单位转化小时计算某一时间段间隔了多少小时
      const diffMinusHour = BN.minus(diffHour, BN.times(24, diffDay)).toNumber()
      const diffResidueHour = diffMinusHour < 0 ? -diffMinusHour : diffMinusHour

      // 以小时为单位转化分钟计算某一时间段间隔了多少分钟
      const diffMinusMinute = BN.minus(diffMinute, BN.times(60, diffHour)).toNumber()
      const diffResidueMinute = diffMinusMinute < 0 ? -diffMinusMinute : diffMinusMinute

      // 以分钟为单位转化秒计算某一时间段间隔了多少秒
      const diffMinusSecond = BN.minus(diffSecond, BN.times(60, diffMinute)).toNumber()
      const diffResidueSecond = diffMinusSecond < 0 ? -diffMinusSecond : diffMinusSecond

      // console.log([
      //   { diffDay, diffHour, diffResidueHour },
      //   { diffHour, diffMinute, diffResidueMinute },
      //   { diffMinute, diffSecond, diffResidueSecond }
      // ])

      let duration = null
      const funcObj = {
        hour: () => {
          if (diffHour > 24) duration = `${diffDay}天${diffResidueHour > 0 ? diffResidueHour + '小时' : ''}`
          else if (diffHour === 24) duration = `1天`
          else if (diffHour > 0 && diffHour < 24) duration = `${diffHour}小时`
          else duration = null
          return duration
        },
        minute: () => {
          if (diffMinute > 60) duration = `${diffHour}小时${diffResidueMinute > 0 ? diffResidueMinute + '分钟' : ''}`
          else if (diffMinute === 60) duration = `1小时`
          else if (diffMinute > 0 && diffMinute < 60) duration = `${diffMinute}分钟`
          else duration = null
          return duration
        },
        second: () => {
          if (diffSecond > 60) duration = `${diffMinute}分钟${diffResidueSecond > 0 ? diffResidueSecond + '秒' : ''}`
          else if (diffSecond === 60) duration = `1分钟`
          else if (diffSecond > 0 && diffSecond < 60) duration = `${diffSecond}秒`
          else duration = null
          return duration
        },
      }
      return funcObj['hour']() || '0'
    },
    /**
     * 【日期+时间】格式化
     * @param {*} date 有效日期数值
     * @param {*} format 默认 YYYY-MM-DD HH:mm:ss
     * @returns
     */
    datetime(date, format = 'YYYY-MM-DD HH:mm:ss') {
      if (!date) return '--'
      return dayjs(date).format(format)
    },
    /**
     * 格式化表格【日期】列(YYYY-MM-DD)
     * @param {*} row
     * @param {*} column
     * @param {*} value
     * @returns
     */
    dateColumn(row, column, value) {
      if (!value) return '--'
      return dayjs(value).format('YYYY-MM-DD')
    },
    /**
     * 格式化表格【日期+时间】列(YYYY-MM-DD HH:mm:ss)
     * @param {} row
     * @param {*} column
     * @param {*} value
     * @returns
     */
    datetimeColumn(row, column, value) {
      if (!value) return '--'
      return dayjs(Number(value)).format('YYYY-MM-DD HH:mm:ss')
    },
    /**
     * 列表的头部搜索时处理下拉框数据
     * @param { Array } formItemList 表单项
     * @param { Array } data 接口返回的lists数据
     * @param { Object } field 表单项的字段
     * {
     * key:   表单项model名 (当多个表单项的下拉数据一样时, 可传入数组, 即可同时设置多个选项值)
     * label: 接口返回的***name
     * value: 接口返回的***id
     * code:  接口返回的***code
     * }
     * @param { Object } type 表单项类型 默认普通select; 可选: select/selectTree
     * @returns [{ label: '',value: '', ...option }]
     */
    handleSetOptions(formItemList = [], data = [], field = null, type = 'select') {
      if (!formItemList?.length || !data?.length || !field) return []
      // 聚合相关逻辑赋值处理
      const assignOptions = (item, data, field) => {
        const optionTypes = {
          select: () => {
            item.data = data.map((optionItem) => {
              return {
                ...optionItem,
                label: field?.code ? optionItem[field.code] + '-' + optionItem[field.label] : optionItem[field.label],
                value: optionItem[field.value],
              }
            })
          },
          selectTree: () => {
            item.options.treeData = data
          },
        }
        optionTypes[type] && optionTypes[type]()
      }
      formItemList.forEach((item) => {
        if (Object.prototype.toString.call(field.key) === '[object Array]') {
          field.key.map((fieldKeyItem) => {
            if (item.model === fieldKeyItem) assignOptions(item, data, field)
          })
        } else {
          if (item.model === field.key) assignOptions(item, data, field)
        }
      })
    },
  }
  
  Vue.prototype.dayjs = dayjs
  // BN 通过 ProvidePlugin 全局引入
  Vue.prototype.BN = BN
  // 全局 EventBus
  Vue.prototype.$GlobalEventBus = EventBus
}

if (typeof window !== void 0 && window.Vue) {
  install(window.Vue)
}

export default install
